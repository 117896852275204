<template>
  <div class="container mt-5">
    <a-row>
      <a-col :xs="{ span: 6 }" :md="{ span: 16 }" :lg="{ span: 22 }">
        <a-row type="flex" justify="space-between" class="mb-3">
          <a-col ::xs="{ span: 24 }" :md="{ span: 2 }" :lg="{ span: 18 }">
            <h4>Kategoriyalar ro'yxati</h4>
          </a-col>
          <a-col :sm="{ span: 2 }" :md="{ span: 4 }" :lg="{ span: 5 }">
            <router-link :to="{ name: 'NewCategory' }">
              <a-button size="large" type="primary">
                <a-icon type="file-add" />
                Kategoriya qo'shish
              </a-button>
            </router-link>
          </a-col>
        </a-row>
        <a-table :columns="columns" :data-source="data" bordered>
          <template slot="name" slot-scope="text, record">
            <router-link
              class="text-dark font-weight-bold"
              :to="{
                name: 'EditCategory',
                params: {
                  id: record.id,
                },
              }"
              ><a-icon type="edit" /> {{ text }}
            </router-link>
          </template>
          <template slot="main" slot-scope="main">
            <a-tag color="green" v-if="main">
              Asosiy
            </a-tag>
            <a-tag color="red" v-if="!main">
              Asosiy
            </a-tag>
          </template>
        </a-table>
      </a-col>
    </a-row>
  </div>
</template>

<script>
const columns = [
  {
    title: 'Nomi',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Asosiy',

    className: 'column-money',
    dataIndex: 'main',
    scopedSlots: { customRender: 'main' },
  },
];
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      columns: columns,
      data: [],
    };
  },

  methods: {
    ...mapActions({
      loadAllCategory: 'category/loadAllCategory',
    }),
  },

  created() {
    this.loadAllCategory().then(({ results }) => {
      this.data = results.map((item) => {
        return {
          key: item.id,
          id: item.id,
          main: item.main,
          name: item.name_ru,
        };
      });
    });
  },
};
</script>
