<template>
  <section>
    <category-list></category-list>
  </section>
</template>

<script>
import CategoryList from '../../components/CategoryCom/CatgeoryList.vue';

export default {
  components: {
    CategoryList,
  },

  created() {
    this.$store.dispatch('category/loadAllCategory');
  },
};
</script>

<style scoped>
.container {
  width: 1100px;
  margin: 0 auto;
}

.ant-col-8 {
  text-align: center;
}
</style>
